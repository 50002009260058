
var swiper1 = new Swiper('.top-slider', {
    loop: true,
    slidesPerView: 1,
    centeredSlides : false,
    autoHeight: true,
    autoplay: {
        delay: 3000,
    },
    preventLinks: false,
    speed: 800,
    breakpoints: {
        1280: {
            slidesPerView: 1,
            centeredSlides : true,
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});



